
import { Badge, Avatar, IconButton, CircularProgress, Divider, Typography, Stack, Popover, Box, LinearProgress } from "@suid/material";
import { createSignal, onCleanup } from "solid-js";
import { QuestionMarkIcon } from '../icons';

interface CandidateProgress {
  width?: number;
  defaultProgress?: number;
  badgeNumber?: number;
  avatar?: string;
  color?: "success" | "primary"
}


const CandidateProgressQuestionmark = () => {
  const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);
  const handlePopoverOpen = (event: { currentTarget: Element }) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = () => Boolean(anchorEl());

  return (
    <>
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={open()}
        anchorEl={anchorEl()}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Stack margin={2} spacing={2}>
          <Typography fontSize="80%" variant="body1">The number of candidates identified for a job role is indicated within the circle. <br />No number equals, candidates are not identified and no open actions for the jobrole.<br /> The progress circle visualized the status of the dialogue with the candidate as explained below. </Typography>

        </Stack>

        <Divider variant="middle">Progress Circle</Divider>
        <Stack margin={2} spacing={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <CircularProgress color="success" variant="determinate" value={0} />
            <Typography variant="body1"> No candidate identified (0%)  </Typography>
          </Stack>

          <Stack direction="row" spacing={2} alignItems="center">
            <CircularProgress color="success" variant="determinate" value={20} />
            <Typography variant="body1"> Candidate(s) identified (20%) </Typography>
          </Stack>

          <Stack direction="row" spacing={2} alignItems="center">
            <CircularProgress color="success" variant="determinate" value={40} />
            <Typography variant="body1"> Interviewing candidate (40%) </Typography>
          </Stack>

          <Stack direction="row" spacing={2} alignItems="center">
            <CircularProgress color="success" variant="determinate" value={60} />
            <Typography variant="body1"> Proposal in place (60%) </Typography>
          </Stack>

          <Stack direction="row" spacing={2} alignItems="center">
            <CircularProgress color="success" variant="determinate" value={80} />
            <Typography variant="body1">  Contract in place (80%) </Typography>
          </Stack>

          <Stack direction="row" spacing={2} alignItems="center">
            <CircularProgress color="success" variant="determinate" value={100} />
            <Typography variant="body1">  Contract in place and all candidates answered (100%) </Typography>
          </Stack>
        </Stack>

      </Popover>

      <IconButton size="small" onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose} color="inherit"
      >

        <QuestionMarkIcon />

      </IconButton>

    </>
  );

}

const CandidateProgressCircle = (props: CandidateProgress) => {

  return (
    <>
      <Box sx={{
        position: "relative",
        display: "inline-flex"
      }}>
        <CircularProgress
          color="success" size={props.width} value={Number(props.defaultProgress)}
          variant="determinate" />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
         
          <IconButton color={props.color} sx={{ position: 'relative', zIndex: 1 }}>
            <Badge
              color="primary" badgeContent={Number(props.badgeNumber)}>
              <Avatar sx={{ width: 56, height: 56 }} src={props.avatar} />
            </Badge>
          </IconButton>
        </Box>
      </Box>
    </>
  );

}

const CandidateProgress = (props: CandidateProgress) => {
  const [progress, setProgress] = createSignal(0);

  const timer = setInterval(() => {
    setProgress((oldProgress) => {
      if (oldProgress === 100) {
        return 0;
      }
      const diff = Math.random() * 10;
      return Math.min(oldProgress + diff, 100);
    });
  }, 500);

  onCleanup(() => clearInterval(timer));

  return (
    <>
      <Box sx={{ width: props.width ? props.width : "100%" }} >
        <LinearProgress variant="determinate" value={props.defaultProgress ? props.defaultProgress : 0} color="success" />
      </Box>
    </>
  );
}

export { CandidateProgress, CandidateProgressCircle, CandidateProgressQuestionmark }